import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../styles/footer/seven.scss'

// const footerLinks = ["Overview", "Features", "Pricing", "Careers", "Help", "Privacy"]

const footerLinks = [
    {
        name: "About us",
        link: "/about"
    },
    {
        name: "Privacy",
        link: "/privacy"
    },
    {
        name: "Terms of Service",
        link: "/tos"
    },
    {
        name: "Forex",
        link: "/forex"
    },
    {
        name: "Crypto",
        link: "/crypto"
    },
    {
        name: "Stocks",
        link: "/stocks"
    }

]
const FooterSeven = () => {
    

  return (

    
    <>
        {/* <div className="footer__seven__cta__container">
            <div className='footer__seven__cta__wrapper'>
                <h1 className='footer__seven__cta__title'>Open an account now</h1>
                <p className='footer__seven__cta__text'>In order to verify your identity, a government issued ID card with your photo, name, and date of birth is required. Please have the documents prepared in advance.</p>
                <span className='footer__seven__buttons'>
                    <button className="footer__seven__button__white">Learn more</button>
                    <button className="footer__seven__button__blue">Get started</button>
                </span>
            </div>


            <hr className='footer__seven__hr'/>
        </div> */}

        <div className="footer__seven__container">

      

        <div className="footer__seven__wrapper">

                <span className='footer__seven__brand'>
                    <img src="/logo-full.svg" alt="logo" className='footer__seven__brand__logo' />

                    {/* <p className='footer__seven__brand__text'>Trading technology that has your back.</p> */}

                        <ul className='footer__seven__brand__links'>
                        {footerLinks.map(link => (
                            <Link to={link.link}  key={link.name}>
                            <li className='footer__seven__brand__link'> <p>{link.name}</p> </li>
                            </Link>
                        ))}
                        </ul>
                   
                </span>

                <span className='footer__seven__disclaimer'> The risk of loss in online trading of stocks, options, futures, currencies, foreign equities, and fixed Income can be substantial.
                <br />
                <br />
                
                Before trading, clients must read the relevant risk disclosure statements on our Warnings and Disclosures page. Trading on margin is only for experienced investors with high risk tolerance. You may lose more than your initial investment. For additional information about rates on margin loans, please see Margin Loan Rates. Security futures involve a high degree of risk and are not suitable for all investors. The amount you may lose may be greater than your initial investment.
                <br />
                <br />
                For trading security futures, read the Security Futures Risk Disclosure Statement. Structured products and fixed income products such as bonds are complex products that are more risky and are not suitable for all investors. Before trading, please read the Risk Warning and Disclosure Statement.
                </span>


                <span className='footer__seven__bottom'>
                <hr />


                <p className='footer__seven__reserved'>© 2022 Defi Access. All rights reserved.</p>          
                </span>

        </div>

        </div>
    </>
  )
}

export default FooterSeven